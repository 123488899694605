import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { Formik, Form, Field } from "formik"
import { contentPaddingY } from "../config"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import Send from "@material-ui/icons/Send"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import * as Yup from "yup"
import Phone from "../components/phone"
import Email from "../components/email"
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles(theme => ({
  form: {
    display: "grid",
    gridTemplateAreas:
      "'firstName lastName' 'email phone' 'message message' 'submit submit' 'status status'",
    gridTemplateColumns: "1fr 1fr",
    minWidth: "55%",
    padding: `0 ${theme.spacing(4)}px`,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  firstName: { gridArea: "firstName" },
  lastName: { gridArea: "lastName" },
  email: { gridArea: "email" },
  phone: { gridArea: "phone" },
  message: { gridArea: "message" },
  status: { gridArea: "status", marginTop: theme.spacing(4) },
  field: { margin: theme.spacing(1) },
  submit: {
    margin: theme.spacing(1),
    gridArea: "submit",
    maxWidth: "50%",
  },
  content: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}))

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "roof-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const classes = useStyles()
  const form = {
    firstName: { label: "First Name" },
    lastName: { label: "Last Name" },
    email: { label: "Email Address" },
    phone: { label: "Phone Number" },
    message: { label: "Message" },
    submit: { type: "submit" },
  }
  const FormSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    phone: Yup.string(),
    message: Yup.string().max(1000),
  })
  const initialValues = Object.keys(form).reduce(
    (initialValues, field) => ({
      ...initialValues,
      [field]: form[field].initialValue || "",
    }),
    {}
  )
  return (
    <Layout bgImage={data.bgImage.childImageSharp.fluid} title="Contact Us">
      <SEO title="Contact Us" />
      <Box py={contentPaddingY} className={classes.content}>
        <Box mb={12}>
          <Typography variant="h4" gutterBottom>
            Start a conversation
          </Typography>
          <Typography variant="h6" gutterBottom>
            We'd love to hear from you!
          </Typography>
          <Typography paragraph>
            Complete a contact form and we will be in touch with you as soon as
            possible.
          </Typography>
          <Typography paragraph>
            If you prefer you can give Jono a call on <Phone />, or send us an
            email directly to <Email />
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={(
            { firstName, lastName, email, phone, message },
            { resetForm, setSubmitting, setStatus }
          ) => {
            fetch("/.netlify/functions/send-email", {
              body: JSON.stringify({
                firstName,
                lastName,
                email,
                phone,
                message,
              }),
              method: "POST",
            })
              .then(response => response.json())
              .then(json => {
                console.log(json)
                console.log("submission success")
                resetForm()
                setSubmitting(false)
                setStatus("success")
              })
              .catch(err => {
                console.error(err)
                console.log("submission error")
                setSubmitting(false)
                setStatus("error")
              })
          }}
          validationSchema={FormSchema}
        >
          {({ isSubmitting, status }) => (
            <Form className={classes.form}>
              {Object.keys(form).map(fieldName =>
                form[fieldName].type === "submit" ? (
                  <Button
                    key={fieldName}
                    size="large"
                    variant="outlined"
                    color="primary"
                    className={classes.submit}
                    endIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : <Send />
                    }
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Sending" : "Send"}
                  </Button>
                ) : (
                  <Field name={fieldName} key={fieldName}>
                    {({ field, meta }) => (
                      <TextField
                        variant="filled"
                        multiline={fieldName === "message"}
                        error={meta.touched && Boolean(meta.error)}
                        helperText={
                          meta.touched && Boolean(meta.error) && meta.error
                        }
                        label={form[fieldName].label}
                        {...field}
                        className={clsx([classes[fieldName], classes.field])}
                      />
                    )}
                  </Field>
                )
              )}
              {status === "error" && (
                <Alert severity="error" className={classes.status}>
                  Sorry. Something went wrong with the contact form. Please call
                  or email directly.
                </Alert>
              )}
              {status === "success" && (
                <Alert severity="success" className={classes.status}>
                  Thanks! We will be in touch soon.
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Layout>
  )
}

export default ContactUsPage
